import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57')
];

export const server_loads = [0,2,3,4,5,6,7,8];

export const dictionary = {
		"/": [~9],
		"/(authenticated)/admin": [10,[2]],
		"/(authenticated)/admin/absences": [11,[2]],
		"/(authenticated)/admin/showCancellations": [12,[2]],
		"/(authenticated)/admin/showTemplates": [13,[2]],
		"/(authenticated)/admin/showTemplates/overview": [14,[2]],
		"/(authenticated)/admin/shows": [15,[2]],
		"/(authenticated)/banners": [16,[2]],
		"/(authenticated)/blog": [17,[2]],
		"/(authenticated)/blog/[id]": [18,[2,3]],
		"/(authenticated)/calendars": [19,[2]],
		"/(authenticated)/calendars/[calendarId]": [20,[2,4]],
		"/(authenticated)/calendars/[calendarId]/edit": [21,[2,4]],
		"/confirm": [54],
		"/consent": [55],
		"/(authenticated)/contacts": [22,[2]],
		"/(authenticated)/djmanagementNotification": [25,[2]],
		"/(authenticated)/djmanagement": [24,[2]],
		"/(authenticated)/djreviews/[id]": [26,[2,5]],
		"/(authenticated)/dj": [23,[2]],
		"/emailVerify/[token]": [~56],
		"/(authenticated)/files": [27,[2]],
		"/(authenticated)/hr/people": [28,[2]],
		"/(authenticated)/hr/reviews": [29,[2]],
		"/(authenticated)/hr/tasks": [30,[2]],
		"/(authenticated)/hr/tasks/completed": [31,[2]],
		"/(authenticated)/logs": [32,[2]],
		"/(authenticated)/mijn": [33,[2]],
		"/(authenticated)/mijn/users/[userId]": [34,[2,6]],
		"/(authenticated)/notifications/admin": [35,[2]],
		"/(authenticated)/notifications/hr": [36,[2]],
		"/(authenticated)/profile": [37,[2]],
		"/(authenticated)/programming": [38,[2]],
		"/(authenticated)/redirect": [~39,[2]],
		"/(authenticated)/scheduleImage": [40,[2]],
		"/schedule": [57],
		"/(authenticated)/staffNotifications": [41,[2]],
		"/(authenticated)/streamKeyNote": [42,[2]],
		"/(authenticated)/system/socket": [43,[2]],
		"/(authenticated)/test": [44,[2]],
		"/(authenticated)/top20": [45,[2]],
		"/(authenticated)/trainings": [46,[2]],
		"/(authenticated)/users": [47,[2]],
		"/(authenticated)/users/statistics": [49,[2]],
		"/(authenticated)/users/[userId]": [48,[2,7]],
		"/(authenticated)/vacatures": [50,[2]],
		"/(authenticated)/vacatures/internal": [52,[2]],
		"/(authenticated)/vacatures/[id]/applications": [51,[2,8]],
		"/(authenticated)/warnings": [53,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';